<template>
  <div class="medfutoro">
    <Navbar />
  </div>

</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navigation/Navbar.vue';

export default {
  name: 'Medfuturo',
  components: {
    Navbar,
  },
};
</script>
